import { computed, effect, Injectable, resource, inject } from "@angular/core";
import { MicroSentryService } from "@micro-sentry/angular";
import { client } from "./api";
import { refreshInterval } from "../shared/shared.utils";

export const DSN_REGEXP =
  /^(?:(\w+):)\/\/(?:(\w+)(?::(\w+))?@)([\w.-]+)(?::(\d+))?\/(.+)/;

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private microSentry = inject(MicroSentryService);

  settingsResource = resource({
    loader: async () => {
      const { data } = await client.GET("/api/settings/");
      return data;
    },
  });

  settings = computed(() => this.settingsResource.value());
  socialApps = computed(() => this.settings()?.socialApps || []);
  billingEnabled = computed(() => this.settings()?.billingEnabled);
  paidForGlitchTip = computed(() => this.settings()?.iPaidForGlitchTip);
  stripePublicKey = computed(() => this.settings()?.stripePublicKey);
  sentryDSN = computed(() => this.settings()?.sentryDSN);
  environment = computed(() => this.settings()?.environment);
  chatwootWebsiteToken = computed(() => this.settings()?.chatwootWebsiteToken);
  enableUserRegistration = computed(
    () => this.settings()?.enableUserRegistration
  );
  enableOrganizationCreation = computed(
    () => this.settings()?.enableOrganizationCreation
  );
  serverTimeZone = computed(() => this.settings()?.serverTimeZone);
  initialLoad = computed(() => this.settingsResource.hasValue());
  useNewSocialCallbacks = computed(
    () => this.settings()?.useNewSocialCallbacks
  );
  version = computed(() => this.settings()?.version);

  constructor() {
    this.refreshSettings();
    effect(() => {
      // Configure microsentry and chatwoot. Use computed functions to avoid
      // running when unnecessary
      const sentryDSN = this.sentryDSN();
      const environment = this.environment();
      const version = this.version();
      const chatwootWebsiteToken = this.chatwootWebsiteToken();
      if (sentryDSN) {
        // Micro-sentry does not support dynamic configuration, force it to
        const options = {
          dsn: sentryDSN,
          environment: environment ? environment : undefined,
          release: version ? "glitchtip@" + version : undefined,
        };
        // https://github.com/Tinkoff/micro-sentry/blob/main/libs/core/src/lib/service/micro-sentry-client.ts#L14
        const searched = DSN_REGEXP.exec(options.dsn);
        const dsn = searched ? searched.slice(1) : [];
        const pathWithProjectId = dsn[5].split("/");
        const path = pathWithProjectId.slice(0, -1).join("/");
        (this.microSentry.apiUrl as string) =
          dsn[0] +
          "://" +
          dsn[3] +
          (dsn[4] ? ":" + dsn[4] : "") +
          (path ? "/" + path : "") +
          "/api/" +
          pathWithProjectId.pop() +
          "/store/";
        (this.microSentry.authHeader as string) =
          "Sentry sentry_version=7,sentry_key=" +
          dsn[1] +
          (dsn[2] ? ",sentry_secret=" + dsn[2] : "");
        if (options.environment) {
          (this.microSentry.environment as string) = options.environment;
        }
        if (options.release) {
          (this.microSentry as any).release = options.release;
        }
      }
      if (chatwootWebsiteToken) {
        (function (d, t) {
          const BASE_URL = "https://app.chatwoot.com";
          const g: any = d.createElement(t),
            s: any = d.getElementsByTagName(t)[0];
          g.src = BASE_URL + "/packs/js/sdk.js";
          s.parentNode.insertBefore(g, s);
          g.onload = function () {
            (window as any).chatwootSDK.run({
              websiteToken: chatwootWebsiteToken,
              baseUrl: BASE_URL,
            });
          };
        })(document, "script");
      }
    });
  }

  triggerPlausibleReport(orgSlug: string | undefined) {
    if (window.plausible) {
      var url = window.location.href;
      url = url.replace(/\/\d+(\/|$)/g, "/<id>/");

      window.plausible("pageview", {
        u: orgSlug ? url.replace(`/${orgSlug}/`, "/<organization_slug>/") : url,
      });
    }
  }

  reload() {
    this.settingsResource.reload();
  }

  private refreshSettings() {
    // Refresh 30s, 5m, 30m...
    refreshInterval([30, 60 * 5], 60 * 30).subscribe(() => this.reload());
  }
}
